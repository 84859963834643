import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';
import { StyledLink } from 'components/StyledComponents';

interface PriceHistoryLinkCellProps {
  link: string;
  value?: string;
  icon?: ReactNode;
  dataItem: any;
}

export const PriceHistoryLinkCell = ({
	link,
	icon,
	dataItem
}: PriceHistoryLinkCellProps) => {
	const { pathname, search } = useLocation();

	return (
		<StyledLink
			// @ts-ignore
			disabled={!dataItem?.is_price_available}
			to={{ pathname, search: search + '&' + link }}
		>
			{icon && <Box component='span'>{icon}</Box>}
		</StyledLink>
	);
};
