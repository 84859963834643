import React from 'react';
import { Grid } from '@mui/material';
import { ExpandButton } from 'components/Buttons';

export const FloatExpandButton = (
	{
		table, 
		loading, 
		right,
		bottom
	} : {
		table: any, 
		loading?: boolean, 
		right?: string,
		bottom?: string,
	})=>(
	<Grid container justifyContent={'end'} px={2}>
		<ExpandButton
			onClick={()=>table.setIsFullScreen(!table.getState().isFullScreen)}
			isExpanded={table.getState().isFullScreen}
			sx={{
				position: table.getState().isFullScreen ? 'static' : 'absolute',
				right: right ? right : '50px',
				bottom: `calc(100% + ${bottom || '20px'} - ${loading ? '8px' : '0px'})`,
				maxHeight: '32px',
				maxWidth: '32px',
			}}
		/>
	</Grid>
);