import { Link } from 'react-router-dom';
import { styled } from '@mui/material';
import { COLORS } from 'utils/consts';

export const StyledLink = styled(Link)(({ disabled, color }: any) => ({
	color: disabled ? '#ccc' : color || 'unset',
	pointerEvents: disabled ? 'none' : 'unset',
	textDecoration: 'none',
	display: 'flex',
	alignItems: 'center',
	gap: '6px',
}));

export const StyledInlineLink = styled(Link)(
	({ disabled, color, style }: any) => ({
		color: disabled ? '#ccc' : color || 'unset',
		pointerEvents: disabled ? 'none' : 'unset',
		textDecoration: 'none',
		display: 'inline-flex',
		alignItems: 'center',
		...style,
	})
);

export const StyledBtnLink = styled(Link)(({ disabled, color }: any) => ({
	backgroundColor: disabled ? '#ccc' : color || COLORS.primary,
	color: disabled ? '#000' : COLORS.white,
	pointerEvents: disabled ? 'none' : 'unset',
	textDecoration: 'none',
	borderRadius: '10px',
	padding: '15px 60px',
	textAlign: 'center',
}));