import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Paper, Grid, Typography, CircularProgress } from '@mui/material';
import moment from 'moment';

import { BaseAccordion } from 'components/Accordion/Accordion';
import { Modal } from 'components/Modal/Modal';
import { RemoteDataTable } from 'components/Tables';
import {
	useAppData,
	useNotifications,
	// useUser
} from 'context';
import { useQueryPagination } from 'hooks';
import { AddNewNoteModalContent } from '../shared/AddNewNoteModalContent/AddNewNoteModalContent';
import { ApplicationRoutesEnum, NotificationsTypeEnum } from 'models/enums';
import {
	clinicalTrialsColumns,
	euTendersColumns,
	matchingATCCodeColumns,
	notesColumns,
} from './columns';
import { FormWithAdditionaFilters } from 'components/Forms';
import { FloatExpandButton } from 'components/Tables/shared/ExpandButton';
import { searchFilters, tenderFilters } from './searchFilters';
import {
	useActiveIngredient,
	useClinicalTrials,
	useProductPresentations,
	useProductTenders,
	useActiveIngredientNotes,
	useMutateActiveIngredientNotes,
} from 'services/queries';
import { COMMON_DATE_FORMAT, EU27_ID } from 'utils/consts';

interface searchInputsIntreface {
	presentation: { name: string; id: number }[];
	pack_size: { name: string; id: number }[];
	strength: { name: string; id: number }[];
	countries: { name: string; id: number }[];
}
interface tenderSearchInputsInterface {
	countries: { name: string; id: number }[];
}

const initialSearchInputs = {
	countries: [],
	presentation: [],
	pack_size: [],
	strength: [],
};
const initialTenderSearchInputs = {
	countries: [],
};

export const ActiveIngredient = () => {
	const { uid } = useParams();
	const { setHeader } = useAppData();
	const { addNotification } = useNotifications();
	// const { user } = useUser();

	const [searchInputs, setSearchInputs] =
		useState<searchInputsIntreface>(initialSearchInputs);
	const [tenderSearchInputs, setTenderSearchInputs] =
		useState<tenderSearchInputsInterface>(initialTenderSearchInputs);
	const [showAddNoteModal, setShowAddNoteModal] = useState(false);

	const {
		data: productDetails,
		// error,
		// isLoading,
		// isFetching,
		// isFetched,
	} = useActiveIngredient(uid);
	const {
		postActiveIngredientNotesAsync,
		// postActiveIngredientNotesLoading
	} = useMutateActiveIngredientNotes();

	useEffect(() => {
		setHeader(productDetails?.name);
	}, [productDetails?.name, setHeader]);

	// const hasAccess = useMemo(() => user?.company?.is_paid, [user]);

	const productQueryParams = useMemo(
		() => ({
			active_ingredient: productDetails?.id,
			product_pack__presentation__in: searchInputs.presentation
				.map((val) => val.id)
				.join(','),
			product_pack__pack_size__in: searchInputs.pack_size
				.map((val) => val.id)
				.join(','),
			product_pack__active_ingredients__strength__in: searchInputs.strength
				.map((val) => val.id)
				.join(','),
			country__in: searchInputs.countries
				.map((val) => val.id)
				.filter((id) => id + '' !== EU27_ID)
				.join(','),
		}),
		[
			productDetails,
			searchInputs.countries,
			searchInputs.strength,
			searchInputs.pack_size,
			searchInputs.presentation,
		]
	);
	const {
		data: prodData,
		state: prodState,
		setPagination: prodSetPagination,
		rowCount: prodRowCount,
		setSorting: prodSorting,
		pageCount: prodPageCount,
		setPageCount: prodSetPageCount,
	} = useQueryPagination(
		useProductPresentations,
		null,
		null,
		productQueryParams,
		{ mandatory: ['active_ingredient'] }
	);

	const clinicalTrialsQueryParams = useMemo(
		() => ({ filter_by_ingredient: productDetails?.id }),
		[productDetails]
	);
	const {
		data: clinicalTrialsData,
		state: clinicalTrialsState,
		setPagination: clinicalTrialsSetPagination,
		rowCount: clinicalTrialsRowCount,
		setSorting: clinicalTrialsSorting,
		pageCount: clinicalTrialsPageCount,
		setPageCount: clinicalTrialsSetPageCount,
	} = useQueryPagination(
		useClinicalTrials,
		null,
		null,
		clinicalTrialsQueryParams,
		{ mandatory: ['filter_by_ingredient'] }
	);

	const notesParams = useMemo(() => ({ id: uid }), [uid]);
	const {
		data: notesData,
		state: notesState,
		setPagination: notesSetPagination,
		rowCount: notesRowCount,
		pageCount: notesPageCount,
		setPageCount: notesSetPageCount,
	} = useQueryPagination(useActiveIngredientNotes, null, notesParams);

	const tendersQueryParams = useMemo(
		() => ({
			active_ingredient: uid,
			country__in: tenderSearchInputs.countries
				.map((val) => val.id)
				.filter((id) => id + '' !== EU27_ID)
				.join(','),
		}),
		[uid, tenderSearchInputs.countries]
	);
	const {
		data: tendersData,
		state: tendersState,
		setPagination: tendersSetPagination,
		rowCount: tendersRowCount,
		setSorting: tendersSorting,
		pageCount: tendersPageCount,
		setPageCount: tendersSetPageCount,
	} = useQueryPagination(useProductTenders, null, null, tendersQueryParams, {
		mandatory: ['active_ingredient'],
		sort: [{ id: 'lot__tender__publication_date', desc: true }],
	});

	const onChangeHandler = (e, type: any) => {
		if (type === 'tender') {
			setTenderSearchInputs((prevInputs) => ({
				...prevInputs,
				[e.target.name]: e.target.value,
			}));
		} else {
			setSearchInputs((prevInputs) => ({
				...prevInputs,
				[e.target.name]: e.target.value,
			}));
		}
	};

	const onResetHandler = (type: any) => {
		if (type === 'tender') {
			setTenderSearchInputs(initialTenderSearchInputs);
		} else {
			setSearchInputs(initialSearchInputs);
		}
	};

	const onAddNoteHandeler = async (
		noteData: any,
		{ setSubmitting, resetForm, setErrors }: any
	) => {
		try {
			const addedNote =
				uid && (await postActiveIngredientNotesAsync({ uid, noteData }));
			resetForm();

			addedNote && addNotification({
				message: 'Note saved!',
				type: NotificationsTypeEnum.SUCCESS,
			});
		} catch (err: any) {
			setErrors(err.data);
		} finally {
			setSubmitting(false);
			setShowAddNoteModal(false);
		}
	};

	return (
		<Grid container spacing={4}>
			{/* <Grid item xs={12}>
        <BaseCard
          loading={isLoading || isFetching}
          cardTitle={"Active Ingredient"}
          dataItem={productDetails}
          cardFields={cardFields}
        />
      </Grid> */}
			<Grid item xs={12}>
				<Paper sx={{ borderRadius: '1rem' }} elevation={0}>
					<RemoteDataTable
						state={prodState}
						data={prodData}
						pageCount={prodPageCount}
						onRowsPerPageChange={prodSetPageCount}
						onPaginationChange={prodSetPagination}
						manualSorting
						onSortingChange={prodSorting}
						rowCount={prodRowCount}
						columns={matchingATCCodeColumns}
						enableRowSelection={false}
						enablePagination
						lastColumnAlignRight
						renderCustomActions={({table})=>(
							<Grid container px={2} pt={2}>
								<FormWithAdditionaFilters
									parentValue={productDetails?.name}
									filters={searchFilters}
									searchInputs={searchInputs}
									onChangeHandler={(e) => onChangeHandler(e, 'product')}
									onResetHandler={() => onResetHandler('product')}
									gridWidth={5}
									fullScreenChangeHandler={()=>table.setIsFullScreen(!table.getState().isFullScreen)}
									isFullScreen={table.getState().isFullScreen}
								>
									<Grid container item xs={12} sm={7} display={'flex'} flexWrap={'wrap'} flexShrink={0}>
										<Typography mr={1} variant='h6' fontWeight={700} lineHeight={'2.2em'} fontSize={'1.1rem'}>
											Found in the following ATC Codes:
											{productDetails?.atc_codes && productDetails?.atc_codes.length ? (
												productDetails?.atc_codes.map((atc_code, index) => (
													<React.Fragment key={atc_code.id}>
														<Link
															to={`${ApplicationRoutesEnum.ATC_CODE_EXPLORER}/${atc_code.id}`}
															style={{
																all: 'unset',
																paddingLeft: index === 0 ? '4px' : 0,
															}}
														>
															<Typography
																color={'primary'}
																component='span'
																fontWeight={700}
																variant='h6'
																sx={{ cursor: 'pointer' }}
															>
																{atc_code.code}
															</Typography>
														</Link>
														{productDetails.atc_codes.length - 1 !== index &&
														', '}
													</React.Fragment>
												))
											) : (
												<Typography
													variant='h6'
													fontWeight={700}
													component='span'
												>
												N/A
												</Typography>
											)}
										</Typography>
									</Grid>
								</FormWithAdditionaFilters>
							</Grid>
						)}
					/>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<BaseAccordion
					title={
						<Fragment>
							{`Clinical Trials Including ${productDetails?.name ? productDetails.name : ''}`}
							{clinicalTrialsState.showProgressBars ? (
								<CircularProgress size={10} sx={{ marginLeft: '4px' }} />
							) : (
								` (${clinicalTrialsRowCount})`
							)}
						</Fragment>
					}
					fullWidth
					enableOverflow
				>
					<RemoteDataTable
						state={clinicalTrialsState}
						data={clinicalTrialsData}
						pageCount={clinicalTrialsPageCount}
						onRowsPerPageChange={clinicalTrialsSetPageCount}
						onPaginationChange={clinicalTrialsSetPagination}
						rowCount={clinicalTrialsRowCount || 0}
						columns={clinicalTrialsColumns}
						enableRowSelection={false}
						allowToolbarOverflow
						enablePagination
						manualSorting
						enableMultiSort={false}
						onSortingChange={clinicalTrialsSorting}
						renderCustomActions={({table}) => (
							<FloatExpandButton 
								table={table} 
								loading={clinicalTrialsState.isLoading} 
							/>
						)}
					/>
				</BaseAccordion>
			</Grid>

			<Grid item xs={12}>
				<BaseAccordion
					title={
						<Fragment>
							{`EU tenders Including ${productDetails ? productDetails.name : '-'}`}
							{tendersState.showProgressBars ? (
								<CircularProgress size={10} sx={{ marginLeft: '4px' }} />
							) : (
								` (${tendersRowCount})`
							)}
						</Fragment>
					}
					fullWidth
				>
					<RemoteDataTable
						state={tendersState}
						columns={euTendersColumns}
						data={tendersData}
						pageCount={tendersPageCount}
						onRowsPerPageChange={tendersSetPageCount}
						onPaginationChange={tendersSetPagination}
						enableRowSelection={false}
						enablePagination
						manualSorting
						rowCount={tendersRowCount}
						onSortingChange={tendersSorting}
						lastColumnAlignRight
						renderCustomActions={({table})=>(
							<Grid container px={2}>
								<FormWithAdditionaFilters
									filters={tenderFilters}
									searchInputs={tenderSearchInputs}
									onChangeHandler={(e) => onChangeHandler(e, 'tender')}
									onResetHandler={() => onResetHandler('tender')}
									fullScreenChangeHandler={()=>table.setIsFullScreen(!table.getState().isFullScreen)}
									isFullScreen={table.getState().isFullScreen}
									gridWidth={5}
									compact
									showReset
								/>
							</Grid>
						)}
					/>
				</BaseAccordion>
			</Grid>
			{/* Hide this section until implemented */}
			{/* <Grid item xs={12}>
        <BaseAccordion
          title={`Countries ${
            productDetails && productDetails.name ? productDetails.name : ""
          } is Commercialized in (0)  ${
            isAccessDisabled ? " - " + upgradeAccountMessage : ""
          } `}
          disabled={isAccessDisabled}
        >
          {!isAccessDisabled && (
            <BaseTable
              columns={countiesCommercializedInColumns}
              data={[]}
              enableRowSelection={false}
              enableFullScreenToggle
              enablePagination
            />
          )}
        </BaseAccordion>
      </Grid> */}
			<Grid item xs={12}>
				<BaseAccordion
					title={
						<Fragment>
							{'Notes '}
							{notesState.showProgressBars ? (
								<CircularProgress size={10} sx={{ marginLeft: '4px' }} />
							) : (
								` (${notesRowCount})`
							)}
						</Fragment>
					}
					accordionActions={[
						{
							actionLabel: 'Add New Note',
							actionFn: () => setShowAddNoteModal(true),
						},
					]}
					fullWidth
				>
					<RemoteDataTable
						state={notesState}
						data={notesData}
						pageCount={notesPageCount}
						onRowsPerPageChange={notesSetPageCount}
						onPaginationChange={notesSetPagination}
						rowCount={notesRowCount}
						columns={notesColumns}
						enableRowSelection={false}
						enablePagination
						lastColumnAlignRight
					/>
				</BaseAccordion>
			</Grid>

			<Grid item xs={12}>
				<Typography variant={'body1'}>
					* Source: WHO ICTRP. Last updated{' '}
					{moment().startOf('week').format(COMMON_DATE_FORMAT)}, Pharma Footpath data
					is updated weekly. Please check WHO ICTRP for the latest data.
				</Typography>
			</Grid>
			<Modal show={showAddNoteModal} width={500}>
				<AddNewNoteModalContent
					onSubmit={onAddNoteHandeler}
					onCancel={() => setShowAddNoteModal(!showAddNoteModal)}
				/>
			</Modal>
		</Grid>
	);
};
