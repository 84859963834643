/* eslint-disable react/prop-types */
import React from 'react';
import { TableCustomCellEnum } from 'models/enums';
import { ActionCell } from './ActionCell/ActionCell';
import { DataCell } from './DataCell/DataCell';
import { IndexCell } from './IndexCell/IndexCell';
import { LinkCell } from './LinkCell/LinkCell';
import { PriceCell } from './PriceCell/PriceCell';
import { AvatarCell } from './AvatarCell/AvatarCell';
import { IconCell } from './IconCell/IconCell';
import { ExternalLink } from './ExternalLink/ExternalLink';
import { ModalLinkCell } from './ModalLink/ModalLink';
import { StatusCell } from './StatusCell/StatusCell';
import { LinksCell } from './LinksCell/LinksCell';

export const CustomTableCell = ({ column, ...props }) => {
	switch (column.cellType) {
		case TableCustomCellEnum.INDEX:
			return <IndexCell {...props} itemNumber={0} />;
		case TableCustomCellEnum.DATA:
			return <DataCell {...props} column={column} />;
		case TableCustomCellEnum.LINK:
			return <LinkCell {...props} column={column} />;
		case TableCustomCellEnum.EXTERNAL_LINK:
			return <ExternalLink {...props} column={column} />;
		case TableCustomCellEnum.ACTION:
			return <ActionCell {...props} column={column} />;
		case TableCustomCellEnum.PRICE:
			return <PriceCell {...props} column={column} />;
		case TableCustomCellEnum.AVATAR:
			return <AvatarCell {...props} column={column} />;
		case TableCustomCellEnum.ICON:
			return <IconCell {...props} column={column} />;
		case TableCustomCellEnum.COUNTRY:
			return <DataCell {...props} column={column} country={true} />;
		case TableCustomCellEnum.MODAL_LINK:
			return <ModalLinkCell {...props} column={column} />;
		case TableCustomCellEnum.STATUS:
			return <StatusCell {...props} column={column} />;
		case TableCustomCellEnum.ACTIVE_INGREDIENTS_LINK:
			return <LinksCell {...props} column={column} />;
		default:
			return <DataCell {...props} column={column} />;
	}
};
