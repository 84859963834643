import { useLocation } from 'react-router-dom';
import { useAppData, useUser } from 'context';
import { ApplicationRoutesEnum } from 'models/enums/ApplicationRoutesEnum';

export const useHeaderTitle = () => {
	const { headerTitle, headerDescription, headerBtn, headerEl } = useAppData();
	const { user } = useUser();
	const location = useLocation();

	const headerProps = {
		title: '' as string | React.ReactNode,
		headerDescription,
		hasBackButton: false,
		headerButton: headerBtn,
		headerElement: headerEl
	};

	const detailPaths = [
		{
			name: 'COMPANY',
			path: '/company-explorer/',
			back: true
		},
		{
			name: 'PRODUCT',
			path: '/product-explorer/',
			back: true
		},
		{
			name: 'ATC_CODE',
			path: '/atc-code-explorer/',
			back: true
		},
		{
			name: 'INGREDIENT',
			path: '/ingredients/',
			back: true
		},
		{
			name: 'WDA_COMPANY',
			path: '/eu-wda-licenses/company/',
			back: true
		},
	];

	const headerElementPaths = [
		'/eu-wda-licenses/company/',
		'/company-profile',
		'/product-explorer/'
	];

	switch (location.pathname) {
		case ApplicationRoutesEnum.HOME:
			if(user?.is_admin){
				headerProps.title = `Hi, ${user?.company?.name || (user?.first_name + ' ' + user?.last_name)}`;
			}else{
				headerProps.title = 'Activity Feed';
			}
			break;
		case ApplicationRoutesEnum.EDIT_PROFILE:
			headerProps.title = 'Edit Profile';
			break;
		case ApplicationRoutesEnum.PRODUCT_EXPLORER:
			headerProps.title = 'Product Explorer';
			break;
		case ApplicationRoutesEnum.PRODUCT_ID:
			headerProps.title = 'Product Explorer';
			break;
		case ApplicationRoutesEnum.COMPANY_EXPLORER:
			headerProps.title = 'Company Explorer';
			break;
		case ApplicationRoutesEnum.PARALLEL_TRADE:
			headerProps.title = 'Parallel Trade Explorer';
			break;
		case ApplicationRoutesEnum.ATC_CODE_EXPLORER:
			headerProps.title = 'ATC Explorer';
			break;
		case ApplicationRoutesEnum.SHORTAGES:
			headerProps.title = 'Shortages';
			break;
		case ApplicationRoutesEnum.TENDER_EXPLORER:
			headerProps.title = 'EU Pharmaceutical Tenders';
			break;
		case ApplicationRoutesEnum.WDA_LICENSES:
			headerProps.title = 'EU WDA License Search';
			break;
		case ApplicationRoutesEnum.EDIT_COMPANY_PROFILE:
			headerProps.title = 'Edit Company Profile';
			headerProps.headerButton = '';
			headerProps.headerElement = null;
			break;
		default:
	}

	if (
		detailPaths.some(element => location.pathname.includes(element.path)) 
    || location.pathname === ApplicationRoutesEnum.COMPANY_PROFILE
	) {
		headerProps.title = headerTitle;

		if (
			location.pathname.includes('/eu-wda-licenses/company/')
      || location.pathname === ApplicationRoutesEnum.COMPANY_PROFILE
		) {
			headerProps.hasBackButton = false;
		} else {
			headerProps.hasBackButton = true;
		}

	} else if (location.pathname.includes('/tenders/')) {
		headerProps.hasBackButton = true;
		headerProps.title = 'EU Pharmaceutical Tenders';
	}

	// disabled headerElement for some paths
	if( 
		!headerElementPaths.some(path => location.pathname.includes(path)) 
		|| location.pathname.includes('/company-profile/edit')
	){
		headerProps.headerElement = null;
	}

	return headerProps;
};
