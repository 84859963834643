import { CustomInputTypeEnum } from 'models/enums/CustomInputTypeEnum';
import { getShortageReasonsSuggestions } from 'services';

export const searchFilters = [
	{
		type: CustomInputTypeEnum.TEXT_SEARCH,
		gridWrapper: 2.3,
		name: 'search',
		label: 'Search',
		placeholder: 'Search by product or active ingredient',
	},
	{
		type: CustomInputTypeEnum.AUTOCOMPLETE_REMOTE,
		gridWrapper: 2.3,
		name: 'reason',
		label: 'Shortage reason',
		placeholder: 'Search Shortage Reason',
		dataService: getShortageReasonsSuggestions,
	},
	{
		type: CustomInputTypeEnum.COUNTRY_AUTOCOMPLETE_STATIC,
		gridWrapper: 2.3,
		name: 'countries',
		label: 'Country',
		placeholder: 'Search country',
	},
];
