import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { Container } from '@mui/system';

import {
	useQueryPagination,
	useSearchParams as useSearchInputParams
} from 'hooks';
import { useShortages } from 'services/queries';

import { EU27_ID } from 'utils/consts';
import { shortageColumns } from './columns';
import { searchFilters } from './searchFilters';

import { BaseCheckbox } from 'components/Inputs';
import { FormWithAdditionaFilters } from 'components/Forms';
import { RemoteDataTable } from 'components/Tables';
import { Modal } from 'components/Modal/Modal';

interface SearchInputsIntreface {
	search: string;
	countries: { name: string; id: string; code: string | number }[];
	reason: { name: string; id: number }[];
	is_resolved_exclude: boolean;
}

const initialSearchInputs = {
	search: '',
	countries: [],
	reason: [],
	is_resolved_exclude: false,
};

export const Shortages = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const shortageInfoId = searchParams.get('info');
	const [searchInputs, setSearchInputs, resetSearchInputs] = useSearchInputParams<SearchInputsIntreface>(
		initialSearchInputs
	);

	const onChangeHandler = (e) => {
		setSearchInputs((prevInputs) => ({
			...prevInputs,
			[e.target.name]: e.target.value,
		}));
	};

	const onChangeCheckboxHandler = (e) => {
		setSearchInputs((prevInputs) => ({
			...prevInputs,
			[e.target.name]: e.target.checked,
		}));
	};

	const shortageQueryParams = useMemo(
		() => ({
			country__in: searchInputs.countries
				.filter((e) => e.id + '' !== EU27_ID)
				.map((val) => val.id)
				.join(','),
			reason__in: searchInputs.reason.map((val) => val.id).join(','),
			...(searchInputs.is_resolved_exclude && {
				is_active: searchInputs.is_resolved_exclude,
			}),
		}),
		[
			searchInputs.countries,
			searchInputs.reason,
			searchInputs.is_resolved_exclude,
		]
	);

	const {
		data: shortageData,
		state: shortageState,
		setPagination: shortageSetPagination,
		rowCount: shortageRowCount,
		setSorting: shortageSorting,
		pageCount: shortagePageCount,
		setPageCount: shortageSetPageCount,
	} = useQueryPagination(
		useShortages,
		searchInputs.search,
		null,
		shortageQueryParams,
		{ sort: [{ id: 'notification_date', desc: true }] }
	);

	return (
		<Paper elevation={0} sx={{borderRadius: '10px'}}>
			<RemoteDataTable
				state={shortageState}
				data={shortageData}
				pageCount={shortagePageCount}
				onRowsPerPageChange={shortageSetPageCount}
				onPaginationChange={shortageSetPagination}
				rowCount={shortageRowCount}
				columns={shortageColumns}
				enableRowSelection={false}
				enableTopToolbar={false}
				enablePagination
				manualSorting
				enableMultiSort={false}
				onSortingChange={shortageSorting}
				compact
				renderCustomActions={({table})=>(
					<Grid container flexDirection={'column'} px={3}>
						<FormWithAdditionaFilters
							filters={searchFilters}
							searchInputs={searchInputs}
							onChangeHandler={onChangeHandler}
							onResetHandler={resetSearchInputs}
							gridWidth={2.2}
							fullScreenChangeHandler={()=>table.setIsFullScreen(!table.getState().isFullScreen)}
							isFullScreen={table.getState().isFullScreen}
							additionalFilters={
								<Grid item xs={3} md={2.9} key={'exclude'}>
									<Box>
										<BaseCheckbox
											onChange={onChangeCheckboxHandler}
											checked={searchInputs.is_resolved_exclude}
											name={'is_resolved_exclude'}
											label={'Exclude resolved shortages'}
										/>
									</Box>
								</Grid>
							}
							compact
							showReset
						/>
					</Grid>
				)}
			/>
			<Modal
				show={!!shortageInfoId}
				// width={'90vw'}
				// height={'80vh'}
				modalTitle={
					shortageData?.find((e) => e.id === Number(shortageInfoId))?.name ||
					' '
				}
				close={() => {
					searchParams.delete('info');
					setSearchParams(searchParams);
				}}
				closeButton
				compact
			>
				<Typography variant='body1'>
					{shortageData?.find((e) => e.id === Number(shortageInfoId))
						?.additional_info || ' '}
				</Typography>
			</Modal>
		</Paper>
	);
};
