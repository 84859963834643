/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import { ActiveIcon, CancelledIcon, DormantIcon, InactiveIcon, WithdrawnIcon } from 'components/Icons';
import { StatusColorEnum, StatusEnum } from 'models/enums';
import { COLORS } from 'utils/consts';

const StatusWrapper = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: COLORS.white,
		maxWidth: 220,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '5px',
		textTransform: 'capitalize',
		padding: '10px 20px',
		boxShadow: '0px 0px 24px 0px #6B6B6B29',
		borderRadius: '10px'
	},
}));


const StatusIcon = ({ status, ...props }) => {
	switch (status) {
		case StatusEnum.ACTIVE:
			return <ActiveIcon {...props} />;
		case StatusEnum.DORMANT:
			return <DormantIcon {...props} />;
		case StatusEnum.INACTIVE:
			return <InactiveIcon {...props} />;
		case StatusEnum.CANCELLED:
			return <CancelledIcon {...props} />;
		case StatusEnum.WITHDRAWN:
			return <WithdrawnIcon {...props} />;
		default:
			return <InactiveIcon {...props} />;
	}
};

export const StatusCell = ({ column, ...props }) => {
	const value = props.getValue();

	return (
		<StyledTooltip
			title={
				<React.Fragment>
					<StatusIcon status={value.label} {...props}></StatusIcon>
					<Typography sx={{
						color: StatusColorEnum[value.label.toUpperCase()],
						fontSize: '12px',
						fontWeight: 500,
						lineHeight: '1em'
					}}>
						{value.label}
					</Typography>
				</React.Fragment>
			}
			placement='top'
		>
			<StatusWrapper>
				<StatusIcon status={value.label} {...props}></StatusIcon>
			</StatusWrapper>
		</StyledTooltip>
	);
};