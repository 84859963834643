import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from '@mui/system';
import { Grid, Paper, Typography } from '@mui/material';

import { useAppData } from 'context';
import { useQueryPagination } from 'hooks';
import {
	useATCCode,
	useATCCodes,
	useProductPresentations,
} from 'services/queries';

import { BaseCheckbox, SearchInput } from 'components/Inputs';
import { RemoteDataTable } from 'components/Tables';
import { FormWithAdditionaFilters } from 'components/Forms';

import { atcCategoriesColumns, productColumns } from './columns';
import { searchFilters } from './searchFilters';

interface SearchInputsIntreface {
  productName: string;
  is_commercial_exclude: boolean;
  countries: { name: string; id: string; code: string | number }[];
  presentation: { name: string; id: number }[];
  pack_size: { name: string; id: number }[];
  strength: { name: string; id: number }[];
}

const initialSearchInputs = {
	productName: '',
	is_commercial_exclude: true,
	countries: [],
	presentation: [],
	pack_size: [],
	strength: [],
};

export const ATCCode = () => {
	const { uid } = useParams();
	const { setHeader, setHeaderDesc } = useAppData();
	const { data: ATCCodeDetails } = useATCCode({ params: { uid } });

	const [searchInputs, setSearchInputs] = useState<SearchInputsIntreface>(initialSearchInputs);

	const onChangeHandler = useCallback((e) => {
		setSearchInputs((prevInputs) => ({
			...prevInputs,
			[e.target.name]: e.target.value,
		}));
	},[]);

	const onChangeCheckboxHandler = useCallback((e) => {
		setSearchInputs((prevInputs) => ({
			...prevInputs,
			[e.target.name]: e.target.checked,
		}));
	},[]);

	const onResetHandler = () => setSearchInputs(initialSearchInputs);

	const atcCodeQueryParams = useMemo(() => ({ parent_id: uid }), [uid]);
	const {
		data: atcData,
		state: atcState,
		setPagination: atcSetPagination,
		rowCount: atcRowCount,
		pageCount: atcPageCount,
		setPageCount: atcSetPageCount,
	} = useQueryPagination(useATCCodes, null, null, atcCodeQueryParams);

	const productQueryParams = useMemo(
		() => ({
			atc_code: uid,
			country__in: searchInputs.countries.map((val) => val.id).join(','),
			product_pack__presentation__in: searchInputs.presentation
				.map((val) => val.id)
				.join(','),
			product_pack__pack_size__in: searchInputs.pack_size
				.map((val) => val.id)
				.join(','),
			product_pack__active_ingredients__strength__in: searchInputs.strength
				.map((val) => val.id)
				.join(','),
			is_commercial_exclude: searchInputs.is_commercial_exclude
				? !searchInputs.is_commercial_exclude
				: null,
		}),
		[
			uid,
			searchInputs.countries,
			searchInputs.presentation,
			searchInputs.pack_size,
			searchInputs.strength,
			searchInputs.is_commercial_exclude,
		]
	);

	const {
		data: prodData,
		state: prodState,
		setPagination: prodSetPagination,
		rowCount: prodRowCount,
		setSorting: productSorting,
		pageCount: productPageCount,
		setPageCount: productSetPageCount,
	} = useQueryPagination(
		useProductPresentations,
		searchInputs.productName,
		null,
		productQueryParams
	);

	useEffect(() => {
		setHeader(ATCCodeDetails?.code);
		setHeaderDesc(ATCCodeDetails?.name);

		return () => {
			setHeaderDesc(null);
		};
	}, [ATCCodeDetails, setHeaderDesc, setHeader]);

	return (
		<Grid container spacing={4}>
			<Grid item container xs={12} alignItems={'center'} columnGap={'15px'}>
				<Typography fontWeight={500} fontSize='16px'>
					Super category:
				</Typography>
				<Typography
					fontWeight={500}
					textTransform='uppercase'
					fontSize={'15px'}
					sx={{
						background: '#fff',
						padding: '16px 44px',
						borderRadius: '10px',
					}}
				>
					{(ATCCodeDetails &&
            ((ATCCodeDetails.parent && ATCCodeDetails.parent.name) ||
              ATCCodeDetails.name)) ||
            ''}
				</Typography>
			</Grid>
			{atcData && atcData.length > 0 && (
				<Grid item xs={12}>
					<RemoteDataTable
						state={atcState}
						data={atcData}
						pageCount={atcPageCount}
						onRowsPerPageChange={atcSetPageCount}
						onPaginationChange={atcSetPagination}
						rowCount={atcRowCount}
						columns={atcCategoriesColumns}
						enableRowSelection={false}
						enableTopToolbar={false}
						compact={false}
					/>
				</Grid>
			)}
			<Grid item xs={12}>
				<Paper sx={{ borderRadius: '1rem' }} elevation={0}>
					<RemoteDataTable
						state={prodState}
						data={prodData}
						pageCount={productPageCount}
						onRowsPerPageChange={productSetPageCount}
						onPaginationChange={prodSetPagination}
						rowCount={prodRowCount}
						columns={productColumns}
						enableRowSelection={false}
						enablePagination
						manualSorting
						enableMultiSort={false}
						onSortingChange={productSorting}
						renderTopToolbarCustomActions={() => (
							<Typography fontWeight='600' p={1}>
								All products in {ATCCodeDetails ? ATCCodeDetails.code : ''} (
								{prodRowCount ? prodRowCount : 0})
							</Typography>
						)}
						renderCustomActions={({table})=>(
							<Grid container px={2} pt={2}>
								<FormWithAdditionaFilters
									parentValue={ATCCodeDetails?.code}
									filters={searchFilters}
									searchInputs={searchInputs}
									onChangeHandler={onChangeHandler}
									onResetHandler={onResetHandler}
									fullScreenChangeHandler={()=>table.setIsFullScreen(!table.getState().isFullScreen)}
									isFullScreen={table.getState().isFullScreen}
								>
									<Grid item xs={3}>
										<SearchInput
											onChange={onChangeHandler}
											value={searchInputs.productName}
											name={'productName'}
											label={'Product name'}
											altLabel={
												'(Search product by name, active ingredient, or ATC code)'
											}
											placeholder={'Search here'}
											fullWidth
										/>
									</Grid>
									<Grid item xs={3} alignSelf='end'>
										<BaseCheckbox
											onChange={onChangeCheckboxHandler}
											checked={searchInputs.is_commercial_exclude}
											name={'is_commercial_exclude'}
											label={'Exclude non-commercialised lines'}
										/>
									</Grid>
								</FormWithAdditionaFilters>
							</Grid>
						)}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};
