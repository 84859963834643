import React, { FC } from 'react';
import { COLORS } from 'utils/consts';
import { MaterialUIButtonColorType, MaterialUIButtonSizeType } from 'models/types';
import { MaterialUISizeEnum } from 'models/enums';
import { SMALL_BORDER_RADIUS, SMALL_VARIANT_BUTTON_HEIGHT, SMALL_VARIANT_BUTTON_WIDTH } from 'utils/consts/themeConsts';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';

interface ExpandButtonInterface {
  size?: MaterialUIButtonSizeType;
  color?: MaterialUIButtonColorType;
  sx?: any;
  onClick?: () => void;
  isExpanded?: boolean;
}

export const ExpandButton: FC<ExpandButtonInterface> = ({ size = 'small', color = 'inherit', sx, onClick, isExpanded = false }) => {
	return (
		<Tooltip title={'Toggle full screen'}>
			<Button
				color={color}
				onClick={onClick}
				size={size}
				sx={{
					...sx,
					backgroundColor: sx?.backgroundColor || COLORS.mediumLightGray,
					borderRadius: SMALL_BORDER_RADIUS,
					height: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_BUTTON_HEIGHT : undefined,
					minWidth: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_BUTTON_WIDTH : undefined,
					maxWidth: size === MaterialUISizeEnum.SMALL ? SMALL_VARIANT_BUTTON_WIDTH : undefined,
				}}
			>
				{isExpanded ? (
					<FullscreenExit htmlColor={sx?.color ? sx?.color : COLORS.darkGray} />
				):(
					<Fullscreen htmlColor={sx?.color ? sx?.color : COLORS.darkGray} />
				)}
			</Button>
		</Tooltip>
	);
};
