import React from 'react';
import { ProductIcon } from 'components/Icons';
import { TableCustomCellEnum } from 'models/enums';
import { ProductLinkCell } from 'components/Tables/shared/UniqueCells';
import { PriceCell } from 'components/Tables/shared/CustomCells/PriceCell/PriceCell';

export const productColumns = [
	{
		header: 'Product',
		accessorKey: 'product_pack__product__name',
		accessorFn: (row) => (
			<ProductLinkCell
				value={
					row?.product_pack?.product ? row?.product_pack?.product.name : null
				}
				className='capitalize'
				link={`/product-explorer/${row?.product_pack?.id}/`}
				params={!row.is_eu_authorized && `?country=${row?.country?.id || '0'}`}
			/>
		),
		cellType: TableCustomCellEnum.DATA,
		minSize: 100,
		maxSize: 250,
		align: 'right',
	},
	{
		header: 'Active Ingredient',
		accessorKey: 'active_ingredients__name',
		accessorFn: (row) =>
			row?.product_pack?.active_ingredients ? (
				<>
					{row.product_pack.active_ingredients
						.filter((val) => Boolean(val.active_ingredient))
						.map((val, index) => (
							<ProductLinkCell
								key={index}
								value={val?.active_ingredient?.name}
								className='capitalize'
								link={`/ingredients/${val?.active_ingredient?.id}/`}
							/>
						))}
				</>
			) : null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		minSize: 100,
		maxSize: 200,
		enableSorting: false,
	},
	{
		header: 'Pack Size',
		accessorKey: 'product_pack__pack_size__numeric_value',
		accessorFn: (row) =>
			row?.product_pack?.pack_size ? row.product_pack.pack_size.name : null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 75,
		maxSize: 75,
	},
	{
		header: 'Presentation',
		accessorKey: 'product_pack__presentation__name',
		accessorFn: (row) =>
			row?.product_pack?.presentation
				? row.product_pack.presentation.name
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 100,
	},
	{
		header: 'Strength',
		accessorKey: 'active_ingredients__strength',
		accessorFn: (row) =>
			row?.product_pack?.active_ingredients
				? row.product_pack.active_ingredients
					.filter((val) => Boolean(val.strength))
					.map((val) => val.strength.name)
					.join(', ')
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 100,
		enableSorting: false,
	},
	{
		header: 'Country',
		accessorKey: 'country__name',
		accessorFn: (row) => {
			const countriesParam = encodeURIComponent(
				JSON.stringify({
					countries: [row.country]
				})
			);
			return (row.country ? (
				<ProductLinkCell
					key={row.country.id}
					value={row.country.name}
					link={'/eu-wda-licenses/'}
					params={`s=${countriesParam}`}
				/>
			) : null);
		},
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 100,
	},
	{
		header: 'MA Holder',
		accessorKey: 'product_pack__product__company__name',
		accessorFn: (row) =>
			row?.product_pack?.product?.company
				? row.product_pack.product.company.name
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		className: 'capitalize',
		minSize: 100,
		maxSize: 170,
	},
	{
		header: 'Ex-factory',
		accessorKey: 'last_price__ex_factory_price',
		accessorFn: (row) => (
			<PriceCell
				getValue={() => row?.last_price?.ex_factory_price}
				row={row}
				column={{ priceSymbolPath: ['last_price', 'currency', 'symbol'] }}
			/>
		),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 100,
	},
	{
		header: 'Wholesale',
		accessorKey: 'last_price__wholesale_price',
		accessorFn: (row) => (
			<PriceCell
				getValue={() => row?.last_price?.wholesale_price}
				row={row}
				column={{ priceSymbolPath: ['last_price', 'currency', 'symbol'] }}
			/>
		),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 100,
	},
	{
		header: 'Retail',
		accessorKey: 'last_price__retail_price',
		accessorFn: (row) => (
			<PriceCell
				getValue={() => row?.last_price?.retail_price}
				row={row}
				column={{ priceSymbolPath: ['last_price', 'currency', 'symbol'] }}
			/>
		),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		className: 'capitalize',
		size: 100,
	},
];

export const euAuthorizedColumns = [
	{
		header: 'Eu Prefix',
		accessorKey: 'eu_prefix_code',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Strength',
		accessorKey: 'strength',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Pack Size',
		accessorKey: 'pack_size',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Presentation',
		accessorKey: 'presentation',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
];

export const activeIngredientsColumns = [
	{
		header: 'Common Name',
		accessorKey: 'name',
		cellType: TableCustomCellEnum.LINK,
		linkBase: 'ingredients/',
		linkField: 'id',
		icon: <ProductIcon />,
		align: 'right',
		className: 'capitalize',
	},
	{
		header: 'Number of identified products',
		accessorKey: 'products_count',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
];
