import React from 'react';
import { FileIcon, LinkArrowIcon } from 'components/Icons';
import { PriceCell } from 'components/Tables/shared/CustomCells/PriceCell/PriceCell';
import { ProductLinkCell } from 'components/Tables/shared/UniqueCells';
import { PriceHistoryLinkCell } from 'components/Tables/shared/UniqueCells/PriceHistoryLinkCell/PriceHistoryLinkCell';
import { TableCustomCellEnum } from 'models/enums';

export const authorisedPresentationColumns = [
	{
		header: 'EU Code',
		accessorKey: 'code',
		accessorFn: (row) => (row?.code ? row.code : null),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Pack Size',
		accessorKey: 'pack_size__name',
		accessorFn: (row) =>
			row?.pack?.pack_size?.name ? row.pack.pack_size.name : null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Presentation',
		accessorKey: 'presentation__name',
		accessorFn: (row) =>
			row?.pack?.presentation?.name ? row.pack.presentation.name : null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Strength',
		accessorKey: 'strength__name',
		accessorFn: (row) =>
			row?.pack?.active_ingredients
				? row.pack.active_ingredients
					.filter((val) => Boolean(val.strength))
					.map((val) => val.strength.name)
					.join(', ')
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: false,
	},
	{
		header: 'Pricing',
		size: 50,
		accessorFn: (row) => (
			<PriceHistoryLinkCell
				dataItem={row}
				link={`priceId=${row?.pack?.id}`}
				icon={
					<LinkArrowIcon
						stroke={!row.is_price_available ? '#ccc' : undefined}
					/>
				}
			/>
		),
		cellType: TableCustomCellEnum.DATA,
		align: 'center',
		enableSorting: false,
		component: 'span',
		title: 'open',
	},
];

export const matchingATCCodeColumns = [
	{
		header: 'Product',
		accessorKey: 'product_pack__product__name',
		accessorFn: (row) => (
			<ProductLinkCell
				value={
					row?.product_pack?.product ? row?.product_pack?.product.name : null
				}
				className='capitalize'
				link={`/product-explorer/${row?.product_pack?.id}/`}
				params={!row.is_eu_authorized && `?country=${row?.country?.id || '0'}`}
			/>
		),
		cellType: TableCustomCellEnum.DATA,
		minSize: 100,
		maxSize: 250,
		align: 'right',
	},
	{
		header: 'Active Ingredient',
		accessorKey: 'active_ingredients__name',
		accessorFn: (row) =>
			row?.product_pack?.active_ingredients ? (
				<>
					{row.product_pack.active_ingredients
						.filter((val) => Boolean(val.active_ingredient))
						.map((val, index) => (
							<ProductLinkCell
								key={index}
								value={val?.active_ingredient?.name}
								className='capitalize'
								link={`/ingredients/${val?.active_ingredient?.id}/`}
							/>
						))}
				</>
			) : null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		minSize: 100,
		maxSize: 200,
		enableSorting: false,
	},
	{
		header: 'Pack Size',
		accessorKey: 'product_pack__pack_size__name',
		accessorFn: (row) =>
			row?.product_pack?.pack_size ? row.product_pack.pack_size.name : null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 75,
		maxSize: 75,
	},
	{
		header: 'Presentation',
		accessorKey: 'product_pack__presentation__name',
		accessorFn: (row) =>
			row?.product_pack?.presentation
				? row.product_pack.presentation.name
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 100,
	},
	{
		header: 'Strength',
		accessorKey: 'active_ingredients__strength',
		accessorFn: (row) =>
			row?.product_pack?.active_ingredients
				? row.product_pack.active_ingredients
					.filter((val) => Boolean(val.strength))
					.map((val) => val.strength.name)
					.join(', ')
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 100,
		enableSorting: false,
	},
	{
		header: 'Country',
		accessorKey: 'country__name',
		accessorFn: (row) => (row.country ? row.country.name : null),
		cellType: TableCustomCellEnum.COUNTRY,
		align: 'right',
		size: 100,
	},
	{
		header: 'MA Holder',
		accessorKey: 'product_pack__product__company__name',
		accessorFn: (row) =>
			row?.product_pack?.product?.company
				? row.product_pack.product.company.name
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		className: 'capitalize',
		minSize: 100,
		maxSize: 170,
	},
	{
		header: 'Ex-factory',
		accessorKey: 'last_price__ex_factory_price',
		accessorFn: (row) => (
			<PriceCell
				getValue={() => row?.last_price?.ex_factory_price}
				row={row}
				column={{ priceSymbolPath: ['last_price', 'currency', 'symbol'] }}
			/>
		),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 100,
	},
	{
		header: 'Wholesale',
		accessorKey: 'last_price__wholesale_price',
		accessorFn: (row) => (
			<PriceCell
				getValue={() => row?.last_price?.wholesale_price}
				row={row}
				column={{ priceSymbolPath: ['last_price', 'currency', 'symbol'] }}
			/>
		),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 100,
	},
	{
		header: 'Retail',
		accessorKey: 'last_price__retail_price',
		accessorFn: (row) => (
			<PriceCell
				getValue={() => row?.last_price?.retail_price}
				row={row}
				column={{ priceSymbolPath: ['last_price', 'currency', 'symbol'] }}
			/>
		),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		className: 'capitalize',
		size: 100,
	},
];

export const clinicalTrialsColumns = [
	{
		header: 'Trial Name',
		accessorKey: 'name',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		minSize: 150,
		maxSize: 160,
		wrap: true
	},
	{
		header: 'Primary Sponsor',
		accessorKey: 'primary_sponsor',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		minSize: 150,
		maxSize: 150,
	},
	{
		header: 'Trial Location',
		accessorKey: 'countries__name',
		accessorFn: (row) =>
			row.countries
				? row.countries.map((country) => country.name).join(', ')
				: null,
		cellType: TableCustomCellEnum.COUNTRY,
		align: 'right',
		enableSorting: false,
		minSize: 250,
		maxSize: 250,
		wrap: true
	},
	{
		header: 'Phase',
		accessorKey: 'phase',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		minSize: 250,
		maxSize: 250,
		wrap: true
	},
	{
		header: 'Link to Trial',
		accessorKey: 'web_address',
		cellType: TableCustomCellEnum.EXTERNAL_LINK,
		linkProps: { target: '_blank', rel: 'noopener noreferrer' },
		icon: <LinkArrowIcon />,
		alignHeader: 'center',
		aligned: 'center',
		enableSorting: false,
		size: 30,
	},
];

export const PTLlListiongColumns = [
	{
		header: 'Product Name',
		accessorKey: 'name',
		accessorFn: (row) =>
			row.product_registration
				? row.product_registration?.pack?.product?.name
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: false,
		size: 150,
	},
	{
		header: 'Strength',
		accessorKey: 'eu_authorized_product__active_ingredients__strength',
		accessorFn: (row) =>
			row.product_registration?.pack?.active_ingredients
				? row.product_registration?.pack?.active_ingredients
					.map((val) => val?.strength?.name)
					.join(', ')
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: false,
		size: 100,
	},
	{
		header: 'Pack Size',
		accessorKey: 'eu_authorized_product__pack_size__name',
		accessorFn: (row) =>
			row.product_registration?.pack
				? row.product_registration?.pack?.pack_size?.name
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: false,
		size: 50,
	},
	{
		header: 'MA Holder',
		accessorKey: 'product_pack__product__company__name',
		accessorFn: (row) =>
			row.product_registration?.pack?.product
				? row.product_registration?.pack?.product?.company?.name
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 150,
	},
	{
		header: 'EU Number',
		accessorKey: 'eu_authorized_product__eu_code',
		accessorFn: (row) =>
			row.product_registration ? row.product_registration.code : null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: false,
		size: 100,
	},
	{
		header: 'Parallel Distrubutor',
		accessorKey: 'company__name',
		accessorFn: (row) => (row.company ? row.company.name : null),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 100,
	},
	{
		header: 'Date of Issue',
		accessorKey: 'date_of_decision',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 100,
	},
	{
		header: 'Destination Country',
		accessorFn: (row) =>
			row.destination_countries
				? row.destination_countries.map((val) => val.name).join(', ')
				: null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		enableSorting: false,
		size: 100,
	},
	{
		header: 'Status',
		accessorKey: 'status',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 50,
	},
];

export const euTendersColumns = [
	{
		header: 'Name',
		accessorKey: 'lot__title',
		accessorFn: (row) =>
			row.lot ? `${row.lot?.number} - ${row.lot?.title}` : null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 200,
	},
	{
		header: 'Tender Number',
		accessorKey: 'lot__tender__number',
		accessorFn: (row) => (row.lot ? row.lot?.tender?.number : null),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 100,
	},
	{
		header: 'Tender Location',
		accessorKey: 'lot__tender__country__name',
		accessorFn: (row) => (row.lot ? row.lot?.tender?.country?.name : null),
		cellType: TableCustomCellEnum.COUNTRY,
		align: 'right',
		enableSorting: false,
		size: 100,
	},
	{
		header: 'Publication Date',
		accessorKey: 'lot__tender__publication_date',
		accessorFn: (row) => (row.lot ? row.lot?.tender?.publication_date : null),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 100,
	},
	{
		header: 'Closing Date',
		accessorKey: 'lot__tender__closing_date',
		accessorFn: (row) => (row.lot ? row.lot?.tender?.closing_date : null),
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 100,
	},
	{
		header: 'Awarded Company',
		accessorKey: 'winning_company__name',
		accessorFn: (row) =>
			row.winning_company ? row.winning_company?.name : null,
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
		size: 200,
		minSize: 200,
		maxSize: 200,
	},
	{
		header: 'Link to Tender',
		accessorKey: 'lot__tender__link',
		accessorFn: (row) => (
			<a
				href={`${row.winning_company ? row.lot?.tender?.link : ''}`}
				target='_blank'
				rel='noopener noreferrer'
			>
				<LinkArrowIcon />
			</a>
		),
		cellType: TableCustomCellEnum.DATA,
		alignHeader: 'center',
		aligned: 'center',
		minSize: '10%',
		enableSorting: false,
	},
];

export const notesColumns = [
	{
		header: 'User Name',
		accessorKey: 'user_name',
		cellType: TableCustomCellEnum.AVATAR,
		align: 'right',
	},
	{
		header: 'Description',
		accessorKey: 'note',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		header: 'Date',
		accessorKey: 'created_at',
		cellType: TableCustomCellEnum.DATA,
		align: 'right',
	},
	{
		size: 50,
		header: 'Note',
		cellType: TableCustomCellEnum.ICON,
		icon: <FileIcon />,
		align: 'right',
		enableSorting: false,
	},
];
