import React, { useCallback, useMemo } from 'react';
import { Container } from '@mui/system';
import { Grid, Box, Paper, Typography } from '@mui/material';

import { BaseCheckbox, SearchInput } from 'components/Inputs';
import { RemoteDataTable } from 'components/Tables';
import { FormWithAdditionaFilters } from 'components/Forms';

import { productColumns } from './columns';
import { searchFilters } from './searchFilters';
import { useQueryPagination } from 'hooks/useQueryPagination';
import { useProductPresentations } from 'services/queries';
import { useSearchParams } from 'hooks';

interface SearchInputsIntreface {
	productName: string;
	is_commercial_exclude: boolean;
	is_empty_price_exclude: boolean;
	countries: { name: string; id: string; code: string | number }[];
	presentation: { name: string; id: number }[];
	pack_size: { name: string; id: number }[];
	strength: { name: string; id: number }[];
	ma_holder: { name: string; id: number }[];
}

const initialSearchInputs = {
	productName: '',
	is_commercial_exclude: true,
	is_empty_price_exclude: false,
	countries: [],
	presentation: [],
	pack_size: [],
	strength: [],
	ma_holder: [],
};

export const ProductExplorer = () => {
	const [searchInputs, setSearchInputs, resetSearchParams] = useSearchParams<SearchInputsIntreface>(
		initialSearchInputs
	);

	const productQueryParams = useMemo(
		() => ({
			country__in: searchInputs.countries.map((val) => val.id).join(','),
			company__in: searchInputs.ma_holder.map((val) => val.id).join(','),
			product_pack__presentation__in: searchInputs.presentation
				.filter((val) => val.id)
				.map((val) => val.id)
				.join(','),
			product_pack__pack_size__in: searchInputs.pack_size
				.map((val) => val.id)
				.join(','),
			product_pack__active_ingredients__strength__in: searchInputs.strength
				.map((val) => val.id)
				.join(','),
			is_commercial_exclude: searchInputs.is_commercial_exclude
				? !searchInputs.is_commercial_exclude
				: null,
			...(searchInputs.is_empty_price_exclude
				? {
					is_price_empty: !searchInputs.is_empty_price_exclude,
				}
				: null),
			...(searchInputs.presentation.find(val => val.name === 'Injectibles')
				? {
					is_injectable: true
				} : null)
		}),
		[
			searchInputs.countries,
			searchInputs.ma_holder,
			searchInputs.presentation,
			searchInputs.pack_size,
			searchInputs.strength,
			searchInputs.is_commercial_exclude,
			searchInputs.is_empty_price_exclude,
		]
	);

	const {
		data: prodData,
		state: prodState,
		setPagination: prodSetPagination,
		rowCount: prodRowCount,
		setSorting: prodSorting,
		pageCount: prodPageCount,
		setPageCount: prodSetPageCount,
	} = useQueryPagination(
		useProductPresentations,
		searchInputs.productName,
		null,
		productQueryParams
	);

	const onChangeHandler = useCallback((e) => {
		setSearchInputs((prevInputs) => ({
			...prevInputs,
			[e.target.name]: e.target.value,
		}));
	}, []);

	const onChangeCheckboxHandler = (e) => {
		setSearchInputs((prevInputs) => ({
			...prevInputs,
			[e.target.name]: e.target.checked,
		}));
	};

	return (
		<Paper elevation={0} sx={{borderRadius: '10px'}}>
			<RemoteDataTable
				state={prodState}
				data={prodData}
				pageCount={prodPageCount}
				onRowsPerPageChange={prodSetPageCount}
				onPaginationChange={prodSetPagination}
				rowCount={prodRowCount}
				columns={productColumns}
				enableRowSelection={false}
				enablePagination
				manualSorting
				enableMultiSort={false}
				onSortingChange={prodSorting}
				renderCustomActions={({table}) => (
					<Grid container flexDirection={'column'} pt={2} px={3}>
						<FormWithAdditionaFilters
							parentValue={searchInputs.productName}
							filters={searchFilters}
							searchInputs={searchInputs}
							onChangeHandler={onChangeHandler}
							onResetHandler={resetSearchParams}
							gridWidth={2.7}
							fullScreenChangeHandler={()=>table.setIsFullScreen(!table.getState().isFullScreen)}
							isFullScreen={table.getState().isFullScreen}
						>
							<Grid item xs={6} md={3}>
							
								{/* // <Typography fontWeight='600' py={1} ml={-1}>
						// 	All Products {prodRowCount ? `(${prodRowCount})` : ''}
						// </Typography> */}
								<SearchInput
									onChange={onChangeHandler}
									value={searchInputs.productName}
									name={'productName'}
									label={'Product name'}
									altLabel={
										'(Search product by name, active ingredient, or ATC code)'
									}
									placeholder={'Search here'}
									fullWidth
								/>
							</Grid>
							<Grid item alignSelf='flex-end' pb={1}>
								<BaseCheckbox
									onChange={onChangeCheckboxHandler}
									checked={searchInputs.is_commercial_exclude}
									name={'is_commercial_exclude'}
									label={'Exclude non-commercialised lines'}
								/>
							</Grid>
							<Grid item alignSelf='flex-end' pb={1}>
								<BaseCheckbox
									onChange={onChangeCheckboxHandler}
									checked={searchInputs.is_empty_price_exclude}
									name={'is_empty_price_exclude'}
									label={'Exclude empty price'}
								/>
							</Grid>
						</FormWithAdditionaFilters>
					</Grid>
				)}
			/>
		</Paper>
	);
};
