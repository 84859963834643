import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Paper, Grid, Typography, CircularProgress } from '@mui/material';
import moment from 'moment';

import { BaseAccordion } from 'components/Accordion/Accordion';
import { BaseCard } from 'components/Cards';
import { Modal } from 'components/Modal/Modal';
import { RemoteDataTable } from 'components/Tables';
import { useAppData, useNotifications, useUser } from 'context';
import { useQueryPagination } from 'hooks';
import { AddNewNoteModalContent } from '../shared/AddNewNoteModalContent/AddNewNoteModalContent';
import { NotificationsTypeEnum } from 'models/enums';
import { upgradeAccountMessage } from 'utils/consts/messages';
import { HistoryPriceModal } from '../shared';
import { cardFields } from './cardField';
import {
	PTLlListiongColumns,
	authorisedPresentationColumns,
	clinicalTrialsColumns,
	euTendersColumns,
	matchingATCCodeColumns,
	notesColumns,
} from './columns';
import { FormWithAdditionaFilters } from 'components/Forms';
import {
	searchFilters,
	prodSearchFilters,
	tenderFilters,
} from './searchFilters';
import { capitalizeString } from 'utils/capitalizeString';
import {
	useProductPresentations,
	useProductRegistrations,
	useClinicalTrials,
	useProductTenders,
	useProductNotes,
	useParallelTradeLicenses,
	// useProductPresentation,
	useMutateProductNotes,
} from 'services/queries';
import { COMMON_DATE_FORMAT, EU27_ID } from 'utils/consts';
import { useProductRegistration } from 'services/queries/ProductQueries/ProductQueries';
import { FloatExpandButton } from 'components/Tables/shared/ExpandButton';
import { HeaderTitle, StyledBtnLink } from 'components/StyledComponents';

interface SearchInputsIntreface {
	presentation: { name: string; id: number }[];
	pack_size: { name: string; id: number }[];
	strength: { name: string; id: number }[];
}

interface ProdSearchInputsIntreface {
	presentation: { name: string; id: number }[];
	pack_size: { name: string; id: number }[];
	strength: { name: string; id: number }[];
	countries: { name: string; id: number }[];
}
interface tenderSearchInputsInterface {
	countries: { name: string; id: number }[];
}

const initialSearchInputs = {
	presentation: [],
	pack_size: [],
	strength: [],
};

const initialProdSearchInputs = {
	presentation: [],
	pack_size: [],
	strength: [],
	countries: [],
};
const initialTenderSearchInputs = {
	countries: [],
};

export const EuAuthorizedProduct = () => {
	const { uid } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const euAuthorizedProductId = searchParams.get('priceId');
	const { setHeaderElement } = useAppData();
	const { addNotification } = useNotifications();
	const { user } = useUser();

	const {
		data: productDetails,
		// error,
		isLoading,
		isFetching,
		// isFetched,
	} = useProductRegistration({
		params: { id: uid },
		queryParams: { country: 0 },
	});

	const {
		postProductNotesAsync,
		// postProductNotesLoading
	} = useMutateProductNotes();

	const [searchInputs, setSearchInputs] =
		useState<SearchInputsIntreface>(initialSearchInputs);
	const [prodSearchInputs, setProdSearchInputs] =
		useState<ProdSearchInputsIntreface>(initialProdSearchInputs);
	const [tenderSearchInputs, setTenderSearchInputs] =
		useState<tenderSearchInputsInterface>(initialTenderSearchInputs);

	const [showAddNoteModal, setShowAddNoteModal] = useState(false);

	useEffect(() => {
		if(productDetails){
			setHeaderElement(
				<Grid container justifyContent={'space-between'} alignItems={'center'}>
					<HeaderTitle component='h1'>
						{productDetails?.pack?.product?.name}
					</HeaderTitle>
					{productDetails?.link_to_source && (
						<StyledBtnLink 
							to={productDetails?.link_to_source}
							target='_blank'
							sx={{padding: '15px'}}
						>
							<Typography variant='subtitle2' textTransform={'uppercase'}>View source</Typography>
						</StyledBtnLink>
					)}
				</Grid>
			);
		}
	}, [productDetails, setHeaderElement]);

	const hasAccess = useMemo(() => user?.company?.is_paid, [user]);

	const onChangeHandler = (e, type) => {
		switch (type) {
			case 'tender':
				setTenderSearchInputs((prevInputs) => ({
					...prevInputs,
					[e.target.name]: e.target.value,
				}));
				break;
			case 'product':
				setProdSearchInputs((prevInputs) => ({
					...prevInputs,
					[e.target.name]: e.target.value,
				}));
				break;
			default:
				setSearchInputs((prevInputs) => ({
					...prevInputs,
					[e.target.name]: e.target.value,
				}));
				break;
		}
	};

	const onResetHandler = (type) => {
		switch (type) {
			case 'tender':
				setTenderSearchInputs(initialTenderSearchInputs);
				break;
			case 'product':
				setProdSearchInputs(initialProdSearchInputs);
				break;
			default:
				setSearchInputs(initialSearchInputs);
				break;
		}
	};

	const authorizedPresentationQueryParams = useMemo(
		() => ({
			search_for: productDetails?.id,
			presentation: searchInputs.presentation.map((val) => val.id).join(','),
			pack_size: searchInputs.pack_size.map((val) => val.id).join(','),
			strength: searchInputs.strength.map((val) => val.id).join(','),
		}),
		[productDetails, searchInputs]
	);
	const {
		data: authPresData,
		state: authPresState,
		setPagination: authPresSetPagination,
		rowCount: authPresRowCount,
		setSorting: authPresSorting,
		pageCount: authPresPageCount,
		setPageCount: authPresSetPageCount,
	} = useQueryPagination(
		useProductRegistrations,
		null,
		null,
		authorizedPresentationQueryParams,
		{ mandatory: ['search_for'] }
	);
	const productQueryParams = useMemo(
		() => ({
			atc_code: productDetails?.pack?.product?.atc_code?.id,
			exclude_product_id: productDetails?.pack?.product?.id,
			is_commercial_exclude: false,
			product_pack__presentation__in: prodSearchInputs.presentation
				.map((val) => val.id)
				.join(','),
			product_pack__pack_size__in: prodSearchInputs.pack_size
				.map((val) => val.id)
				.join(','),
			product_pack__active_ingredients__strength__in: prodSearchInputs.strength
				.map((val) => val.id)
				.join(','),
			country__in: prodSearchInputs.countries
				.map((val) => val.id)
				.filter((id) => id !== 999)
				.join(','),
		}),
		[
			productDetails,
			prodSearchInputs.countries,
			prodSearchInputs.strength,
			prodSearchInputs.pack_size,
			prodSearchInputs.presentation,
		]
	);

	const {
		data: prodData,
		state: prodState,
		setPagination: prodSetPagination,
		rowCount: prodRowCount,
		setSorting: prodSorting,
		pageCount: prodPageCount,
		setPageCount: prodSetPageCount,
	} = useQueryPagination(
		useProductPresentations,
		null,
		null,
		productQueryParams,
		{
			mandatory: ['atc_code', 'exclude_product_id'],
		}
	);

	const clinicalTrialsQueryParams = useMemo(
		() => ({ filter_by_product: productDetails?.pack?.product?.id }),
		[productDetails]
	);
	const {
		data: clinicalTrialsData,
		state: clinicalTrialsState,
		setPagination: clinicalTrialsSetPagination,
		rowCount: clinicalTrialsRowCount,
		setSorting: clinicalTrialsSorting,
		pageCount: clinicalTrialsPageCount,
		setPageCount: clinicalTrialsSetPageCount,
	} = useQueryPagination(
		useClinicalTrials,
		null,
		null,
		clinicalTrialsQueryParams,
		{ mandatory: ['filter_by_product'] }
	);

	const parallelTradeQueryParams = useMemo(
		() => ({ search_for_registration_id: productDetails?.id }),
		[productDetails]
	);
	const {
		data: parallelLicencesData,
		state: parallelLicencesState,
		setPagination: parallelLicencesSetPagination,
		rowCount: parallelLicencesRowCount,
		setSorting: parallelLicencesSorting,
		pageCount: parallelLicencesPageCount,
		setPageCount: parallelLicencesSetPageCount,
	} = useQueryPagination(
		useParallelTradeLicenses,
		null,
		null,
		parallelTradeQueryParams,
		{
			mandatory: ['search_for_registration_id'],
			sort: [{ id: 'date_of_decision', desc: true }],
		}
	);

	const tendersQueryParams = useMemo(
		() => ({
			product: productDetails?.pack?.product?.id,
			country__in: tenderSearchInputs.countries
				.map((val) => val.id)
				.filter((id) => id + '' !== EU27_ID)
				.join(','),
		}),
		[productDetails, tenderSearchInputs.countries]
	);
	const {
		data: tendersData,
		state: tendersState,
		setPagination: tendersSetPagination,
		rowCount: tendersRowCount,
		setSorting: tendersSorting,
		pageCount: tendersPageCount,
		setPageCount: tendersSetPageCount,
	} = useQueryPagination(useProductTenders, null, null, tendersQueryParams, {
		mandatory: ['product'],
		sort: [{ id: 'lot__tender__publication_date', desc: true }],
	});

	const notesParams = useMemo(
		() => ({ id: productDetails?.id }),
		[productDetails?.id]
	);
	const {
		data: notesData,
		state: notesState,
		setPagination: notesSetPagination,
		rowCount: notesRowCount,
		pageCount: notesPageCount,
		setPageCount: notesSetPageCount,
	} = useQueryPagination(useProductNotes, null, notesParams);

	const onAddNoteHandeler = async (
		noteData: any,
		{ setSubmitting, resetForm, setErrors }: any
	) => {
		try {
			const addedNote = uid && (await postProductNotesAsync({
				productId: productDetails?.id,
				noteData,
			}));
			resetForm();

			addedNote && addNotification({
				message: 'Note saved!',
				type: NotificationsTypeEnum.SUCCESS,
			});
			// onSetRefetch();
		} catch (err: any) {
			setErrors(err.data);
		} finally {
			setSubmitting(false);
			setShowAddNoteModal(false);
		}
	};
	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<BaseCard
					loading={isLoading || isFetching}
					cardTitle={'Centrally Authorized Product'}
					dataItem={{
						...productDetails,
						// Trimmed EU code without last 4 charachters
						code: productDetails?.code?.slice(0, -4)
					}}
					cardFields={cardFields}
					copyBrief
				/>
			</Grid>

			<Grid item xs={12}>
				<Paper sx={{ borderRadius: '1rem' }} elevation={0}>
					<RemoteDataTable
						state={authPresState}
						data={authPresData}
						pageCount={authPresPageCount}
						onRowsPerPageChange={authPresSetPageCount}
						columns={authorisedPresentationColumns}
						rowCount={authPresRowCount}
						onPaginationChange={authPresSetPagination}
						enableRowSelection={false}
						enablePagination
						onSortingChange={authPresSorting}
						renderCustomActions={({table}) => (
							<Grid container px={2} pt={2}>
								<FormWithAdditionaFilters
									parentValue={productDetails?.pack?.product?.atc_code?.code}
									filters={searchFilters}
									searchInputs={searchInputs}
									onChangeHandler={(e) => onChangeHandler(e, '')}
									onResetHandler={() => onResetHandler('')}
									fullScreenChangeHandler={()=>table.setIsFullScreen(!table.getState().isFullScreen)}
									isFullScreen={table.getState().isFullScreen}
								>							
									<Typography fontWeight='600' p={1}>
										Authorised Presentation
									</Typography>
								</FormWithAdditionaFilters>
							</Grid>
						)}
					/>
				</Paper>
			</Grid>
			{productDetails?.pack?.product?.atc_code?.code && (
				<Grid item xs={12}>
					<BaseAccordion
						title={
							<Fragment>
								{`Products with Matching ATC Code - ${productDetails.pack.product.atc_code.code}
              `}
								{prodState.showProgressBars ? (
									<CircularProgress size={10} sx={{ marginLeft: '4px' }} />
								) : (
									` (${prodRowCount})`
								)}
								{hasAccess ? '' : ' - ' + upgradeAccountMessage}
							</Fragment>
						}
						disabled={!hasAccess}
						fullWidth
					>
						{hasAccess && (
							<RemoteDataTable
								state={prodState}
								data={prodData}
								pageCount={prodPageCount}
								onRowsPerPageChange={prodSetPageCount}
								onPaginationChange={prodSetPagination}
								rowCount={prodRowCount || 0}
								columns={matchingATCCodeColumns}
								enableRowSelection={false}
								enablePagination
								manualSorting
								enableMultiSort={false}
								onSortingChange={prodSorting}
								renderCustomActions={({table}) => (
									<Grid container px={2}>
										<FormWithAdditionaFilters
											parentValue={productDetails?.pack?.product?.atc_code?.code}
											filters={prodSearchFilters}
											searchInputs={prodSearchInputs}
											onChangeHandler={(e) => onChangeHandler(e, 'product')}
											onResetHandler={() => onResetHandler('product')}
											fullScreenChangeHandler={()=>table.setIsFullScreen(!table.getState().isFullScreen)}
											isFullScreen={table.getState().isFullScreen}
										/>
									</Grid>
								)}
							/>
						)}
					</BaseAccordion>
				</Grid>
			)}
			<Grid item xs={12}>
				<BaseAccordion
					title={
						<Fragment>
							{`Clinical Trials Including ${productDetails?.pack?.active_ingredients
								? productDetails.pack.active_ingredients
									.map((val) =>
										capitalizeString(val.active_ingredient.name)
									)
									.join(', ')
								: ' '}`}
							{clinicalTrialsState.showProgressBars ? (
								<CircularProgress size={10} sx={{ marginLeft: '4px' }} />
							) : (
								` (${clinicalTrialsRowCount})`
							)}
						</Fragment>
					}
					fullWidth
					enableOverflow
				>
					<RemoteDataTable
						state={clinicalTrialsState}
						data={clinicalTrialsData}
						pageCount={clinicalTrialsPageCount}
						onRowsPerPageChange={clinicalTrialsSetPageCount}
						onPaginationChange={clinicalTrialsSetPagination}
						rowCount={clinicalTrialsRowCount || 0}
						columns={clinicalTrialsColumns}
						enableRowSelection={false}
						enablePagination
						manualSorting
						enableMultiSort={false}
						onSortingChange={clinicalTrialsSorting}
						allowToolbarOverflow
						renderCustomActions={({table}) => (
							<FloatExpandButton 
								table={table} 
								loading={clinicalTrialsState.isLoading}
							/>
						)}
					/>
				</BaseAccordion>
			</Grid>

			<Grid item xs={12}>
				<BaseAccordion
					title={
						<Fragment>
							{`Parallel Trade Licenses Listing ${productDetails?.pack?.product
								? productDetails.pack.product.name
								: ''}
          `}
							{parallelLicencesState.showProgressBars ? (
								<CircularProgress size={10} sx={{ marginLeft: '4px' }} />
							) : (
								` (${parallelLicencesRowCount})`
							)}
							{hasAccess ? '' : ' - ' + upgradeAccountMessage}
						</Fragment>
					}
					disabled={!hasAccess}
					fullWidth
					enableOverflow
				>
					{hasAccess && (
						<RemoteDataTable
							state={parallelLicencesState}
							data={parallelLicencesData}
							pageCount={parallelLicencesPageCount}
							onRowsPerPageChange={parallelLicencesSetPageCount}
							onPaginationChange={parallelLicencesSetPagination}
							rowCount={parallelLicencesRowCount}
							columns={PTLlListiongColumns}
							enableRowSelection={false}
							enablePagination
							manualSorting
							enableMultiSort={false}
							onSortingChange={parallelLicencesSorting}
							allowToolbarOverflow
							renderCustomActions={({table}) => (
								<FloatExpandButton 
									table={table} 
									loading={parallelLicencesState.isLoading}
								/>
							)}
						/>
					)}
				</BaseAccordion>
			</Grid>
			<Grid item xs={12}>
				<BaseAccordion
					title={
						<Fragment>
							{`EU tenders Including ${productDetails?.pack?.active_ingredients
								? productDetails.pack.active_ingredients
									.map((val) =>
										capitalizeString(val.active_ingredient.name)
									)
									.join(', ')
								: ' '}`}
							{tendersState.showProgressBars ? (
								<CircularProgress size={10} sx={{ marginLeft: '4px' }} />
							) : (
								` (${tendersRowCount})`
							)}
						</Fragment>
					}
					fullWidth
				>
					<RemoteDataTable
						state={tendersState}
						columns={euTendersColumns}
						data={tendersData}
						pageCount={tendersPageCount}
						onRowsPerPageChange={tendersSetPageCount}
						enableRowSelection={false}
						enablePagination
						manualSorting
						onPaginationChange={tendersSetPagination}
						rowCount={tendersRowCount}
						onSortingChange={tendersSorting}
						lastColumnAlignRight
						renderCustomActions={({table}) => (
							<Grid container px={2}>
								<FormWithAdditionaFilters
									// parentValue={productDetails?.name}
									filters={tenderFilters}
									searchInputs={tenderSearchInputs}
									onChangeHandler={(e) => onChangeHandler(e, 'tender')}
									onResetHandler={() => onResetHandler('tender')}
									gridWidth={5}
									fullScreenChangeHandler={()=>table.setIsFullScreen(!table.getState().isFullScreen)}
									isFullScreen={table.getState().isFullScreen}
									compact
									showReset
								/>
							</Grid>
						)}
					/>
				</BaseAccordion>
			</Grid>
			<Grid item xs={12}>
				<BaseAccordion
					title={
						<Fragment>
							{'Notes '}
							{notesState.showProgressBars ? (
								<CircularProgress size={10} sx={{ marginLeft: '4px' }} />
							) : (
								` (${notesRowCount})`
							)}
						</Fragment>
					}
					accordionActions={[
						{
							actionLabel: 'Add New Note',
							actionFn: () => setShowAddNoteModal(true),
						},
					]}
					fullWidth
				>
					<RemoteDataTable
						state={notesState}
						data={notesData}
						pageCount={notesPageCount}
						onRowsPerPageChange={notesSetPageCount}
						onPaginationChange={notesSetPagination}
						rowCount={notesRowCount}
						columns={notesColumns}
						enableRowSelection={false}
						enablePagination
						lastColumnAlignRight
					/>
				</BaseAccordion>
			</Grid>
			<Grid item xs={12}>
				<Typography variant={'body1'}>
					* Source: WHO ICTRP. Last updated{' '}
					{moment().startOf('week').format(COMMON_DATE_FORMAT)}, Pharma Footpath data
					is updated weekly. Please check WHO ICTRP for the latest data.
				</Typography>
			</Grid>
			<Modal show={!!showAddNoteModal} width={500}>
				<AddNewNoteModalContent
					onSubmit={onAddNoteHandeler}
					onCancel={() => setShowAddNoteModal(!showAddNoteModal)}
				/>
			</Modal>
			<Modal
				show={!!euAuthorizedProductId}
				width={'90vw'}
				height={'80vh'}
				close={() => {
					searchParams.delete('priceId');
					setSearchParams(searchParams);
				}}
			>
				<HistoryPriceModal
					product={euAuthorizedProductId}
					euAuthorizedProduct={
						euAuthorizedProductId ? euAuthorizedProductId : undefined
					}
					onCancel={() => {
						searchParams.delete('priceId');
						setSearchParams(searchParams);
					}}
				/>
			</Modal>
		</Grid>
	);
};
